// you probably don't change the classes
class AnimateOnScrollOptions{
    debug:boolean=false;
    scrollSelector:string='';
    callbackFunction:Function=function(this:HTMLElement, scrollPercent:number){ console.log("scrollPercent", scrollPercent, "for", this); };
    constructor(){
    }
}

class AnimateOnScroll{
    options:AnimateOnScrollOptions;
    scrollElements:JQuery;
    arrScrollElements:HTMLElement[]=[];
    constructor(props:AnimateOnScrollOptions) {
        this.options=props;
        let self=this;
        this.scrollElements=ZIDX.$(this.options.scrollSelector);
        this.scrollElements.each(function (this: HTMLElement) {
            self.arrScrollElements.push(this);
        });
        ZIDX.$(window).on("scroll wheel", function(){
            self.update();
        });
        ZIDX.$(window).on("clientresize resize", function(){
            self.update();
        });
        this.update();
    }
    update(){
        for(let i=0;i<this.arrScrollElements.length;i++){
            let scrollElement=this.arrScrollElements[i];
            let position:DOMRect=scrollElement.getBoundingClientRect();
            let topPosition=(window.scrollY+window.innerHeight)-scrollElement.offsetTop;
            let bottomPosition=(window.scrollY)-(scrollElement.offsetTop+position.height);
            let scrollPercent=0;
            if(topPosition>0){
                if(bottomPosition<0) {
                    scrollPercent=topPosition/(position.height+window.innerHeight);
                }else{
                    scrollPercent=1;
                }
            }else{
                scrollPercent=0;
            }
            this.options.callbackFunction(scrollElement, scrollPercent);
        }
    }
}
// you'll need to change the configuration
window.ZIDXOptions.loadOnceFunctions.push(function(){
    ZIDX.$(document).ready(function(){
        var options=new AnimateOnScrollOptions();
        options.scrollSelector=".scrollClass";
        options.callbackFunction=function(scrollElement:HTMLElement, scrollPercent:number){
            // returns number from 0 to 1.
            // uncomment to debug
            // console.log("scrollPercent", scrollPercent, "for", scrollElement);
            let startDeadZone=.35;
            let endDeadZone=.65;
            // let videoElement=ZIDX.$(".videoElementClass"); // you'll need to change this
            if(scrollPercent>0 && scrollPercent<startDeadZone) {
                let divisor=startDeadZone;
                scrollElement.style.opacity = "" + (scrollPercent / divisor);
            }else if(scrollPercent<1 && scrollPercent>endDeadZone) {
                let divisor = 1 - endDeadZone;
                scrollElement.style.opacity = "" + (1 - ((scrollPercent - endDeadZone) / divisor));
            }else if(scrollPercent>0 && scrollPercent<1){
                // make sure video is playing here.
                // videoElement.play(); // use wistia api instead
            }else{
                scrollElement.style.opacity = "1";
                // pause the video here.
                // videoElement.pause(); // use wistia api instead
            }
        }
        let animateOnScroll=new AnimateOnScroll(options);
    });
});